@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

.small-form {
  @apply min-w-[350px] min-h-[450px];
}

.close-button {
  @apply absolute top-0 right-0 p-2 underline text-xs;
}

.header {
  @apply text-blue-900 font-bold uppercase text-sm;
}

.blue-button {
  @apply bg-blue-900 px-2 lg:py-1 py-2 text-xs rounded-lg min-w-12 text-white disabled:bg-opacity-50 hover:bg-opacity-80;
}

.red-button {
  @apply bg-red-500 px-2 lg:py-1 py-2 text-xs rounded-lg min-w-12 text-white disabled:bg-opacity-50 hover:bg-opacity-80;
}

.small-form-button {
  @apply bg-blue-900 px-2 py-2 text-xs rounded-lg min-w-12 w-full text-white disabled:bg-opacity-50 hover:bg-opacity-80;
}

.green-button {
  @apply bg-green-500 px-2 lg:py-1 py-2 text-xs rounded-lg min-w-12 text-white disabled:bg-opacity-50;
}

.bubble {
  @apply bg-white text-gray-600 rounded-lg;
}

.active-pill {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.inactive-pill {
  @apply bg-gray-200 text-gray-600 px-2 py-1 rounded-full border text-xs border-blue-900 min-w-12 w-fit text-center cursor-pointer;
}

.basic-input {
  @apply border border-gray-300 p-2 rounded-lg w-full;
}

.activeLinkStyle {
  @apply bg-white min-w-12 text-blue-900 shadow-lg rounded-lg font-bold p-2 cursor-pointer;
}

.inactiveLinkStyle {
  @apply text-gray-600 min-w-12 font-bold hover:bg-white cursor-pointer p-2 rounded-lg hover:shadow-lg;
}

.links-container {
  @apply flex items-center justify-start gap-4 bg-gray-200 rounded-lg p-1 lg:w-fit w-full;
}

.pending {
  @apply bg-yellow-500 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.unpaid {
  @apply bg-red-500 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.completed {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.paid {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.rejected {
  @apply bg-red-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.inactive {
  @apply bg-gray-200 text-gray-600 px-2 py-1 rounded-full border text-xs border-blue-900 min-w-12 w-fit text-center cursor-pointer;
}

.activeStatus {
  @apply bg-blue-900 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.processing {
  @apply bg-yellow-300 text-gray-600 px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.card {
  @apply bg-white shadow-sm rounded-md p-2;
}

.approved {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.admitted {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.returned {
  @apply bg-red-300 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.resolved {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.dispatched {
  @apply bg-green-300 text-gray-600 px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.comment {
  @apply bg-gray-200 text-gray-600 rounded-md shadow-sm p-2;
}

.page {
  @apply bg-white shadow-md rounded-lg w-full min-h-screen p-4 pt-8;
}

.high {
  @apply bg-red-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.medium {
  @apply bg-yellow-500 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.low {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.unpublished {
  @apply bg-red-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.published {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}
